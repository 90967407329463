<mat-sidenav-container>
  @if (isLoggedIn()) {
  <mat-sidenav
    #sidenav
    class="mat-elevation-z4"
    [opened]="navOpen()"
    (closed)="closeSideNav()"
    [mode]="mobileNav() === true ? 'over' : 'side'"
  >
    <mat-toolbar class="logo-toolbar">
      <img
        class="main-logo"
        routerLink="."
        (click)="reload()"
        src="static/assets/images/glitchtip-logo-v1.svg"
        alt="GlitchTip"
      />
    </mat-toolbar>
    @for (org of organizations(); track org.id) { } @if ( (contextLoaded()) &&
    organizations().length === 0 && (canCreateOrg()) ) {
    <a
      mat-button
      id="create-new-link"
      routerLink="/organizations/new"
      class="org-create no-org-create"
      i18n
      >Create New Organization</a
    >
    } @else {
    <button
      mat-button
      id="org-dropdown"
      [disabled]="contextLoaded() === false"
      [matMenuTriggerFor]="menu"
      class="org-dropdown"
    >
      <div class="label-container">
        <span class="overflow">
          {{ activeOrganization()?.name }}
        </span>
        <span class="down-caret">▼</span>
      </div>
    </button>
    }
    <mat-menu #menu="matMenu">
      @for (organization of organizations(); track organization.id) {
      <button (click)="setOrganization(organization.slug)" mat-menu-item>
        {{ organization.name }}
      </button>
      }
      <mat-divider></mat-divider>
      @if (canCreateOrg()) {
      <a mat-menu-item id="create-new-link" routerLink="/organizations/new" i18n
        >Create New Organization</a
      >
      } @else {
      <span
        mat-menu-item
        disabled
        matTooltip="Organization creation is currently disabled"
        matTooltipPosition="below"
      >
        Create New Organization
      </span>
      }
    </mat-menu>
    <mat-nav-list class="nav-link-list">
      <a
        mat-list-item
        [disabled]="!activeOrganization()"
        [routerLink]="[activeOrganizationSlug(), 'issues']"
        routerLinkActive="active-route"
        i18n
        >Issues</a
      >
      <a
        mat-list-item
        [disabled]="!activeOrganization()"
        [routerLink]="[activeOrganizationSlug(), 'uptime-monitors']"
        routerLinkActive="active-route"
        i18n
        >Uptime Monitors</a
      >
      <a
        mat-list-item
        [disabled]="!activeOrganization()"
        [routerLink]="[
          activeOrganizationSlug(),
          'performance',
          'transaction-groups'
        ]"
        routerLinkActive="active-route"
        i18n
        >Performance</a
      >
      <mat-divider class="org-details-divider"></mat-divider>
      <a
        mat-list-item
        [disabled]="!activeOrganization()"
        [routerLink]="[activeOrganizationSlug(), 'projects']"
        routerLinkActive="active-route"
        i18n
        >Projects</a
      >
      <a
        mat-list-item
        [disabled]="!activeOrganization()"
        [routerLink]="[activeOrganizationSlug(), 'releases']"
        routerLinkActive="active-route"
        i18n
        >Releases</a
      >
      <a
        data-test-settings
        mat-list-item
        [disabled]="!activeOrganization()"
        [routerLink]="[activeOrganizationSlug(), 'settings']"
        routerLinkActive="active-route"
        i18n
        >Settings</a
      >
      <a
        class="sub-items"
        mat-list-item
        [disabled]="!activeOrganization()"
        [routerLink]="['/', activeOrganizationSlug(), 'settings', 'projects']"
        routerLinkActive="active-route"
        i18n
        >Projects</a
      >
      @if (billingEnabled()) {
      <a
        class="sub-items"
        mat-list-item
        [routerLink]="[
          '/',
          activeOrganizationSlug(),
          'settings',
          'subscription'
        ]"
        routerLinkActive="active-route"
        i18n
        >Subscription</a
      >
      }
      <a
        mat-list-item
        [routerLink]="['/', activeOrganizationSlug(), 'settings', 'teams']"
        routerLinkActive="active-route"
        class="sub-items"
        i18n
        >Teams</a
      >
      <a
        mat-list-item
        [routerLink]="['/', activeOrganizationSlug(), 'settings', 'members']"
        routerLinkActive="active-route"
        class="sub-items"
        i18n
        >Members</a
      >
      <mat-divider class="org-details-divider"></mat-divider>
      <a
        mat-list-item
        [routerLink]="['/profile']"
        routerLinkActive="active-route"
        i18n
        >Profile</a
      >
      <a
        mat-list-item
        [routerLink]="['/profile']"
        routerLinkActive="active-route"
        [routerLinkActiveOptions]="{ exact: true }"
        class="sub-items"
        i18n
        >Account</a
      >
      <a
        mat-list-item
        [routerLink]="['/profile', 'multi-factor-auth']"
        routerLinkActive="active-route"
        [routerLinkActiveOptions]="{ exact: true }"
        class="sub-items"
        i18n
        >Multi-factor Authentication</a
      >
      <a
        mat-list-item
        [routerLink]="['/profile', 'notifications']"
        routerLinkActive="active-route"
        [routerLinkActiveOptions]="{ exact: true }"
        class="sub-items"
        i18n
        >Notifications</a
      >
      <a
        mat-list-item
        [routerLink]="['/profile', 'auth-tokens']"
        routerLinkActive="active-route"
        [routerLinkActiveOptions]="{ exact: true }"
        class="sub-items"
        i18n
        >Auth Tokens</a
      >
      <button mat-menu-item (click)="this.logout()" i18n>Log Out</button>
    </mat-nav-list>
    @if ((paidForGlitchTip()) === false) {
    <mat-card class="nagware">
      <mat-card-header>
        <mat-card-title>
          <h3 i18n>Support GlitchTip</h3>
        </mat-card-title>
      </mat-card-header>
      <mat-card-content>
        <ul class="mat-body-1">
          <li i18n
            >Donate
            <a
              target="_blank"
              rel="noopener noreferrer"
              href="https://liberapay.com/GlitchTip/donate"
            >
              via Liberapay
            </a>
          </li>
          <li i18n>
            Switch to
            <a
              target="_blank"
              rel="noopener"
              href="https://app.glitchtip.com/register?utm_medium=website&utm_source=glitchtip&utm_campaign=selfhost_nagware"
              >Hosted GlitchTip</a
            >
          </li>
          <li i18n>
            Purchase support:
            <a
              href="mailto:sales@glitchtip.com?subject=Purchase support inquiry"
              >sales&#64;glitchtip.com</a
            >
          </li>
        </ul>
      </mat-card-content>
    </mat-card>
    }
    <div class="mat-typography version">
      <a [routerLink]="['/system-info']" routerLinkActive="active-route" i18n
        >GlitchTip {{ version() }}</a
      >
    </div>
  </mat-sidenav>
  }

  <mat-sidenav-content>
    @if (isLoggedIn()) {
    <gt-mobile-nav-toolbar
      [activeOrg]="activeOrganization()"
      (buttonClicked)="toggleSideNav()"
    ></gt-mobile-nav-toolbar>
    }
    <div class="wrapper">
      <ng-content></ng-content>
    </div>
  </mat-sidenav-content>
</mat-sidenav-container>
